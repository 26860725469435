/*!

 =========================================================
 * Material Dashboard - v2.0.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/now-ui-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard/blob/master/LICENSE.md)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 @import "core/variables";
 @import "core/mixins";
 @import "core/core-bootstrap";
 @import "core/reboot";

// Core Components
@import "core/layout";
@import "core/buttons";
@import "core/checkboxes";
@import "core/cards";
@import "core/custom-forms";
@import "core/switches";
@import "core/radios";
@import "core/carousel";
@import "core/forms";
@import "core/input-group";
@import "core/list-group";
@import "core/nav";
@import "core/images";
@import "core/navbar";
@import "core/badges";
@import "core/alerts";
@import "core/pagination";
@import "core/pills";
@import "core/info-areas";
@import "core/type";
@import "core/tabs";
@import "core/tooltip";
@import "core/popover";
@import "core/modal";
@import "core/dropdown";
@import "core/drawer";
@import "core/progress";
@import "core/togglebutton";
@import "core/ripples";
@import "core/footers";
@import "core/sidebar-and-main-panel";
@import "core/fixed-plugin";
@import "core/tables";
@import "core/misc";
@import "core/pages";
@import "core/social-buttons";
@import "core/responsive";

 //plugin scss
@import "core/plugins/animate";
@import "core/plugins/chartist";
@import "core/plugins/perfect-scrollbar";
